@font-face {
font-family: 'recoleta';
src: url(/_next/static/media/4da7448bd04fa32b-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'recoleta Fallback';src: local("Arial");ascent-override: 97.19%;descent-override: 34.99%;line-gap-override: 0.00%;size-adjust: 102.89%
}.__className_21ace8 {font-family: 'recoleta', 'recoleta Fallback'
}

@font-face {
font-family: 'greycliffRegular';
src: url(/_next/static/media/0da9d61d0d893d94-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'greycliffRegular Fallback';src: local("Arial");ascent-override: 75.40%;descent-override: 21.14%;line-gap-override: 19.31%;size-adjust: 103.58%
}.__className_f76820 {font-family: 'greycliffRegular', 'greycliffRegular Fallback'
}

@font-face {
font-family: 'greycliffBold';
src: url(/_next/static/media/2e9d405ef023cb43-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'greycliffBold Fallback';src: local("Arial");ascent-override: 74.42%;descent-override: 20.87%;line-gap-override: 24.87%;size-adjust: 104.94%
}.__className_bf8b05 {font-family: 'greycliffBold', 'greycliffBold Fallback'
}

